import { useCallback } from "react";

import { DateTime } from "luxon";

import { generateRecurrenceRuleString } from "../../../../Recurrence";
import {
  combineRunProps,
  type OverrideRunProps,
  parseErrorResponse,
  useRequestWithFeedback,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { useSelectedRecipient } from "../../entities/recipients";
import { useInstitution } from "../../entities";
import { useACHCompany } from "../../entities/achCompanies";
import { useLibrary } from "../../../providers";

import { formatValuesForRecurrenceString } from "./utils";
import { API } from "./api";
import {
  ACHPaymentForm,
  transformToApiFields,
  transformToScheduledApiFields,
} from "./ACHPayment.form";

export const useCreateACHPayment = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateACHPayment.Response,
    API.CreateACHPayment.Error
  >();
  const t = useLibrary("translations");
  const { values } = ACHPaymentForm.useForm();
  const recipient = useSelectedRecipient() as API.Recipient;
  const achCompany = useACHCompany() as API.ACHCompany;
  const institution = useInstitution();
  const { throwToast } = useLibrary("toasts");

  const createACHPayment = useCallback(
    (
      idempotencyKey: UUID,
      overrideRunProps: OverrideRunProps<
        API.CreateACHPayment.Response,
        API.CreateACHPayment.Error
      > = {},
    ) => {
      // start date can be 2 different formats
      const parseDate = (dateString: string) => {
        const formats = ["MM/dd/yyyy", "yyyy-MM-dd"];

        return (
          formats
            .map((format) => DateTime.fromFormat(dateString, format))
            .find((parsed) => parsed.isValid) || null
        );
      };

      const formattedStartDate = parseDate(values.startDate);
      const today = DateTime.now();
      const isStartDateToday = formattedStartDate?.hasSame(today, "day");
      const isOneTimePaymentForToday =
        !values.recurringRule?.isVisible && isStartDateToday;
      const userInputtedEntryDesc = values.entryDesc;

      const getRecurrenceRuleString = () => {
        const { frequency, startDate, untilDate, count } =
          formatValuesForRecurrenceString(values);
        const recurringRuleString = generateRecurrenceRuleString(
          frequency,
          startDate,
          untilDate,
          count,
        );
        return recurringRuleString;
      };

      const todayOneTimePaymentValues = {
        ...values,
        recipient: recipient.id,
        achCompany: achCompany.id,
        entryDesc:
          userInputtedEntryDesc || institution?.ach_business_description || "",
      };

      const scheduledValues = {
        ...todayOneTimePaymentValues,
        recurringRuleString: getRecurrenceRuleString(),
        transactionType: "credit",
      };

      const transformedValues = isOneTimePaymentForToday
        ? transformToApiFields(todayOneTimePaymentValues)
        : transformToScheduledApiFields(scheduledValues);

      send({
        action: API.createACHPayment(idempotencyKey, transformedValues),
        ...combineRunProps<
          API.CreateACHPayment.Response,
          API.CreateACHPayment.Error
        >(
          {
            onError: async (error) => {
              const { errors } = await parseErrorResponse(error);
              const defaultError = t.getString(
                "ach-payment-error-banner",
                null,
                "We could not process your ACH payment. Please try again.",
              );
              throwToast({
                kind: "error",
                message: errors.length
                  ? `${errors[0].description}`
                  : defaultError,
              });
            },
            onSuccess: (response) => {
              let message = t.getString(
                "ach-payment-success-banner",
                null,
                "Payment sent.",
              );

              if (response.state === "awaiting_approval") {
                message = t.getString(
                  "ach-payment-approval-success-banner",
                  null,
                  "ACH payment submitted for approval.",
                );
              }

              throwToast({
                kind: "success",
                message,
              });
            },
          },
          overrideRunProps,
        ),
      });
    },
    [
      values,
      recipient,
      achCompany,
      institution?.ach_business_description,
      send,
      t,
      throwToast,
    ],
  );

  return useObjectMemo({
    createACHPayment,
    loading,
  });
};
