import React, { useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { useLocalization } from "@fluent/react";
import {
  Button,
  ContextForm,
  Dialog,
  Row,
  formatNumber,
  useNotificationContext,
  MenuButton,
  useFormData,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import BillPaySchedule from "byzantine/src/BillPaySchedule";
import { featureEnabled } from "byzantine/src/Feature";
import TransferSchedule from "byzantine/src/TransferSchedule";
import AccountContext from "../contexts/AccountContext";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import SimpleTransferFields, {
  TRANSFER_FORM_TYPES,
} from "../transfer/SimpleTransferFields";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const EditDialogBody = ({
  fromAccount,
  toAccount,
  amount,
  frequency,
  nextTransferDate,
  itemId,
  closeDialog,
  itemType,
  memo,
  limits,
  refetch,
}) => {
  const { sendNotificationToParent } = useNotificationContext();
  const { formData, onChange } = useFormData({
    frequency,
    amount: formatNumber(amount),
    memo,
    to_account_id: toAccount.id,
    from_account_id: fromAccount.id,
    date: nextTransferDate
      ? DateTime.fromISO(nextTransferDate).toFormat("MM/dd/yyyy")
      : DateTime.now().toFormat("MM/dd/yyyy"),
  });
  const { accounts } = useContext(AccountContext);
  const itempotencyKey = useMemo(
    () => uuidv4(),
    [
      itemId,
      itemType,
      formData.frequency,
      formData.amount,
      formData.memo,
      formData.to_account_id,
      formData.from_account_id,
      formData.date,
    ],
  );

  const onEdit = async (callback) => {
    const schedule = new TransferSchedule({
      amount: `${formData.amount}`,
      from_account_id: formData.from_account_id,
      to_account_id: formData.to_account_id,
      frequency: formData.frequency,
      start_date: DateTime.fromFormat(formData.date, "M/d/yyyy").toFormat(
        "yyyy-MM-dd",
      ),
      memo: formData.memo,
      transfer_uuid: itemId,
      itempotencyKey,
    });
    try {
      await schedule.update();
    } catch (error) {
      callback(error);
      return;
    }
    sendNotificationToParent({
      type: "success",
      text: `${itemType} edited.`,
    });
    // see if we need to redirect to the (possibly) new associated account page
    if (
      window?.location?.pathname &&
      !window.location.pathname.match(
        new RegExp(`(${formData.from_account_id}|${formData.to_account_id})`),
      )
    ) {
      const newFromAccount = accounts.find(
        (a) => a.id === formData.from_account_id,
      );
      if (newFromAccount?.isInternal()) {
        // if the source is internal, navigate there
        window.location.assign(
          `/accounts/${formData.from_account_id}?success=${itemType}+edited.`,
        );
        return;
      } else {
        // otherwise go to destination
        const newToAccount = accounts.find(
          (a) => a.id === formData.to_account_id,
        );
        if (newToAccount?.isInternal()) {
          window.location.assign(
            `/accounts/${formData.to_account_id}?success=${itemType}+edited.`,
          );
          return;
        }
      }
    }
    refetch();
    callback();
  };

  return (
    <ContextForm nativeForm={false} data={formData} onChange={onChange}>
      <div className="margin--top--s">
        <SimpleTransferFields
          data={formData}
          limits={limits}
          formType={TRANSFER_FORM_TYPES.SIMPLE_TRANSFER__EDIT}
        />
      </div>

      <div className="margin--top--l">
        <Row alignItems="center" justifyContent="end">
          <Row.Item shrink>
            <Button kind="negative" onClick={closeDialog} label="Cancel" />
          </Row.Item>
          <Row.Item shrink>
            <ContextForm.Action onSubmit={onEdit}>
              <Button label="Save changes" />
            </ContextForm.Action>
          </Row.Item>
        </Row>
      </div>
    </ContextForm>
  );
};

EditDialogBody.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fromAccount: PropTypes.object.isRequired,
  toAccount: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  nextTransferDate: PropTypes.string,
  itemId: PropTypes.string,
  itemType: PropTypes.oneOf(["Payment", "Transfer"]),
  defaultStartDate: PropTypes.string,
  limits: PropTypes.object,
  memo: PropTypes.string,
  refetch: PropTypes.func,
};

const EditDialog = ({
  isOpen,
  setIsOpen,
  fromAccount,
  toAccount,
  amount,
  frequency,
  nextTransferDate,
  itemId,
  itemType,
  memo,
  limits,
  refetch,
}) => {
  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onUserDismiss={closeDialog}
      title="Edit your transfer"
    >
      <EditDialogBody
        closeDialog={closeDialog}
        fromAccount={fromAccount}
        toAccount={toAccount}
        amount={amount}
        frequency={frequency}
        nextTransferDate={nextTransferDate}
        itemId={itemId}
        itemType={itemType}
        memo={memo}
        limits={limits}
        refetch={refetch}
      />
    </Dialog>
  );
};

EditDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  fromAccount: PropTypes.object.isRequired,
  toAccount: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  nextTransferDate: PropTypes.string,
  itemId: PropTypes.string,
  itemType: PropTypes.oneOf(["Payment", "Transfer"]),
  defaultStartDate: PropTypes.string,
  limits: PropTypes.object,
  memo: PropTypes.string,
  refetch: PropTypes.func,
};

const DeleteDialog = ({
  description,
  isOpen,
  isRecurring,
  setIsOpen,
  itemDescription,
  itemId,
  itemType,
  url,
  refetch,
  isACHPayment,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const frequency = isRecurring ? "recurring" : "upcoming";
  const itemTypeString = isACHPayment ? itemType : itemType.toLowerCase();
  const descriptionString =
    !isACHPayment && isRecurring ? "transfer" : description;
  const closeDialog = () => {
    setIsOpen(false);
  };
  const onDelete = async (callback) => {
    const payload = itemType === "Transfer" ? { id: itemId } : {};
    try {
      await ApiHttp.fetch(url, { method: "DELETE" }, payload);
      setIsOpen(false);
      callback();
      sendNotification({
        type: "success",
        text: `${itemType} deleted.`,
      });
      /* refetch and reload the table */
      refetch();
    } catch (e) {
      callback(`There was an error deleting ${itemDescription}.`);
    }
  };

  const getDescription = () => (
    <>
      <div>
        {l10n.getString("delete-modal-content", {
          frequency,
          descriptionString,
        })}
      </div>
      {isACHPayment && isRecurring ? (
        <>
          <div className="padding--top--l" />
          <div>{l10n.getString("delete-modal-content-ach")}</div>
        </>
      ) : null}
    </>
  );

  const getTitle = () =>
    l10n.getString("delete-modal-title", { frequency, itemTypeString });

  return (
    <Dialog isOpen={isOpen} onUserDismiss={closeDialog} title={getTitle()}>
      {getDescription()}
      <div className="padding--top--l" />
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button kind="negative" onClick={closeDialog} label="Cancel" />
        </Row.Item>
        <Row.Item shrink>
          <ContextForm>
            <ContextForm.Action onSubmit={onDelete}>
              <Button label="Yes, delete" />
            </ContextForm.Action>
          </ContextForm>
        </Row.Item>
      </Row>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isRecurring: PropTypes.bool,
  itemDescription: PropTypes.string,
  itemId: PropTypes.string,
  itemType: PropTypes.oneOf(["Payment", "Transfer", "ACH payment"]),
  url: PropTypes.string,
  refetch: PropTypes.func,
  limits: PropTypes.object,
  isACHPayment: PropTypes.bool,
};

const TransferRow = ({
  transfer,
  accountUuid,
  fetchScheduledTransfers,
  limits,
}) => {
  const userFeatures = useUserFeatures();
  const { currentUser } = useCurrentUser();

  /* renders a row for both recurring and one-time transfers */

  /* recurring rule rows have an additional frequency column and
  their next transfer is displayed as "Next transfer: mm/dd/YY" instead of
  "Month DD, YYYY" */

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { accounts } = useContext(AccountContext);
  const currentAccount = accounts.find((a) => a.id === accountUuid);
  const { from_account, to_account } = transfer;
  const isACHPayment = transfer.ach_company;
  const transferSchedule = TransferSchedule.deserialize(
    {
      from_account_id: from_account?.id,
      to_account_id: to_account?.id,
      recurring_rule: transfer.rrule,
      ...transfer,
    },
    [to_account, from_account].filter((n) => n),
  );

  const { description, amount, next } =
    transferSchedule.displayForAccount(currentAccount);
  const menuItems = [];

  if (transferSchedule.isEditable(userFeatures)) {
    menuItems.push(
      <MenuButton.Item
        key="edit-button"
        label="Edit"
        startIcon="edit-2"
        onSelect={() => {
          setIsEditDialogOpen(true);
        }}
      />,
    );
  }

  if (transferSchedule.isDeletable(userFeatures, currentUser)) {
    menuItems.push(
      <MenuButton.Item
        key="delete-button"
        label="Delete"
        startIcon="trash-2"
        onSelect={() => {
          setIsDeleteDialogOpen(true);
        }}
      />,
    );
  }

  const getDescription = () => {
    if (isACHPayment) {
      return `ACH payment to ${transfer.recipientName}`;
    }
    return description;
  };

  return (
    <div className="row-wrapper">
      <div className="row-items-column">
        <div className="row-item">
          <div>{getDescription()}</div>
          <div className="fontSize--s fontColor--secondary">{next}</div>
        </div>
        {transferSchedule.is_recurring && (
          <div className="row-item responsive-text-styling">
            {transferSchedule.frequency}
          </div>
        )}
      </div>
      <div className="row-items-column">
        <div className="row-item number align-right">{amount}</div>
        <div className="row-item">
          <div style={{ alignSelf: "end" }}>
            {menuItems.length > 0 && (
              <MenuButton triggerIcon="more-vertical">{menuItems}</MenuButton>
            )}
          </div>
        </div>
        {transferSchedule.isEditable(userFeatures) && (
          <EditDialog
            description={transferSchedule?.getDescription?.()}
            isOpen={isEditDialogOpen}
            setIsOpen={setIsEditDialogOpen}
            fromAccount={from_account}
            toAccount={to_account}
            amount={transferSchedule.amount}
            frequency={transferSchedule.frequency}
            nextTransferDate={transferSchedule.next_transfer_at}
            itemId={transfer.id}
            memo={transferSchedule.memo}
            itemType="Transfer"
            limits={limits}
            refetch={fetchScheduledTransfers}
          />
        )}
        {transferSchedule.isDeletable(userFeatures, currentUser) && (
          <DeleteDialog
            description={getDescription()}
            isOpen={isDeleteDialogOpen}
            setIsOpen={setIsDeleteDialogOpen}
            isRecurring={transferSchedule.is_recurring}
            itemDescription={getDescription()}
            itemId={transfer.id}
            itemType={isACHPayment ? "ACH payment" : "Transfer"}
            refetch={fetchScheduledTransfers}
            url={
              isACHPayment
                ? `ach_payments/scheduled/${transfer.uuid}`
                : "transfers/scheduled"
            }
            isACHPayment={isACHPayment}
          />
        )}
      </div>
    </div>
  );
};
TransferRow.propTypes = {
  transfer: PropTypes.object.isRequired,
  accountUuid: PropTypes.string.isRequired,
  fetchScheduledTransfers: PropTypes.func.isRequired,
  limits: PropTypes.object,
};

const PaymentRow = ({ payment, fetchPayments }) => {
  const userFeatures = useUserFeatures();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { is_recurring } = payment;

  const menuItems = [];

  if (featureEnabled(userFeatures, { or: ["bill_pay"] })) {
    menuItems.push(
      <MenuButton.Item
        key="delete-button"
        label="Delete"
        startIcon="trash-2"
        onSelect={() => {
          setIsDeleteDialogOpen(true);
        }}
      />,
    );
  }

  return (
    <div className="row-wrapper">
      <div className="row-items-column">
        <div className="row-item">
          <div>{payment.getDescription()}</div>
          <div className="fontSize--s fontColor--secondary">
            {payment.getNextPaymentText()}
          </div>
        </div>
        {is_recurring && (
          <div className="row-item responsive-text-styling">
            {payment.frequency}
          </div>
        )}
      </div>
      <div className="row-items-column">
        <div className="row-item number align-right">
          {payment.getAmountText()}
        </div>
        <div className="row-item">
          <div style={{ alignSelf: "end" }}>
            {menuItems.length > 0 && (
              <MenuButton triggerIcon="more-vertical">{menuItems}</MenuButton>
            )}
          </div>
          {isDeleteDialogOpen && (
            <DeleteDialog
              description={payment.getDescription()}
              isOpen={isDeleteDialogOpen}
              setIsOpen={setIsDeleteDialogOpen}
              itemId={payment.id}
              itemDescription={payment.getDescription()}
              itemType="Payment"
              refetch={fetchPayments}
              url={`payments/${payment.id}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
PaymentRow.propTypes = {
  payment: PropTypes.instanceOf(BillPaySchedule).isRequired,
  fetchPayments: PropTypes.func.isRequired,
};

const TransferTable = ({
  transfers,
  payments,
  accountUuid,
  isRecurring,
  fetchScheduledTransfers,
  fetchPayments,
  hasTopBorder,
  limits,
}) => (
  /* renders the table for both recurring and one-time transfers and billpay payments */
  /* ACH payments displayed using TransferRow */
  /* recurring transfers table has an additional "frequency" column */
  <div
    className={cc([
      "card-table",
      "kebab",
      {
        four: isRecurring,
        three: !isRecurring,
        "no-table-top-border": !hasTopBorder,
        transactionTable: !isRecurring,
      },
    ])}
  >
    <div className="row-wrapper">
      <div className="row-item-header">DESCRIPTION</div>
      {isRecurring && <div className="row-item-header">FREQUENCY</div>}
      <div className="row-item-header align-right">AMOUNT</div>
      <div className="row-item-header" />
    </div>
    {transfers.map((t) => (
      <TransferRow
        key={t.id}
        transfer={t}
        accountUuid={accountUuid}
        fetchScheduledTransfers={fetchScheduledTransfers}
        limits={limits}
      />
    ))}
    {payments.map((p) => (
      <PaymentRow key={p.id} payment={p} fetchPayments={fetchPayments} />
    ))}
  </div>
);
TransferTable.propTypes = {
  transfers: PropTypes.array.isRequired,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(BillPaySchedule)).isRequired,
  accountUuid: PropTypes.string.isRequired,
  isRecurring: PropTypes.bool.isRequired,
  fetchScheduledTransfers: PropTypes.func.isRequired,
  fetchPayments: PropTypes.func.isRequired,
  hasTopBorder: PropTypes.bool.isRequired,
  limits: PropTypes.object,
};

export default TransferTable;
