import { useLocalization } from "@fluent/react";
import { Navigate } from "react-router-dom";
import User from "byzantine/src/User";
import {
  Button,
  ContextForm,
  Row,
  useFormData,
  useNotificationContext,
  PhoneTextInput,
  phoneFormatter,
} from "cerulean";
import type { InstitutionFeatures } from "byzantine/src/types";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";

interface PhoneNumberEditDialogBodyProps {
  closeDialog: () => void;
}

const PhoneNumberEditDialogBody = ({
  closeDialog,
}: PhoneNumberEditDialogBodyProps) => {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { sendNotificationToParent } = useNotificationContext();
  const { formData, onChange } = useFormData({
    number: phoneFormatter(currentUser?.phone?.number || ""),
  });
  const { l10n } = useLocalization();
  const features = useUserFeatures() as InstitutionFeatures;

  const validateField = (value: string) => {
    if (!value) {
      return l10n.getString("error-required", null, "Must be filled in.");
    }

    return null;
  };

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    try {
      const updatedUser = await currentUser?.updatePhoneNumber(formData.number);
      if (updatedUser) {
        // `cleanedUpdatedUser` is a `updatedUser` object with properties removed
        // if they are empty or undefined. We preserve `null` properties.
        //
        // We do this to prevent overriding unchanged values in `...currentUser`.
        const cleanedUpdatedUser = Object.fromEntries(
          Object.entries(updatedUser).filter(
            ([, v]) =>
              ![undefined, '""', "[]", "{}"].includes(JSON.stringify(v)),
          ),
        );
        setCurrentUser(new User({ ...currentUser, ...cleanedUpdatedUser }));
      }
      callback();
      sendNotificationToParent({
        type: "dismissible_success",
        text: l10n.getString(
          "phone-updated-success-message",
          null,
          "Phone updated. If you intend to use this phone as a Two-Factor Authentication device, please add it [here](/settings/security).",
        ),
      });
      closeDialog();
    } catch (error: unknown) {
      const e = error as { number: string };
      callback(e);
    }
  };

  if (!features?.phone_change) {
    return <Navigate to={"/profile"} replace />;
  }

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field validate={validateField}>
        <PhoneTextInput
          label="Phone number"
          aria-label="Phone number"
          field="number"
        />
      </ContextForm.Field>
      <div className="margin--bottom--xl" />
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button
            type="button"
            onClick={closeDialog}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <ContextForm.Action onSubmit={onSubmit}>
            <Button kind="primary" label="Save changes" />
          </ContextForm.Action>
        </Row.Item>
      </Row>
    </ContextForm>
  );
};

export default PhoneNumberEditDialogBody;
