import { useCallback } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { Dialog } from "cerulean";
import AddressEditDialogBody from "./Address/AddressEditDialog";
import PhoneNumberEditDialogBody from "./ContactInfoCard/PhoneNumberEditDialog";
import EmailEditDialogBody from "./ContactInfoCard/EmailEditDialog";
import UsernameEditDialogBody from "./ContactInfoCard/UsernameEditDialog";
import { getEditDialogType } from "./useIsProfileEditDialogOpen";

interface EditDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

const EditDialog = ({ isOpen, closeDialog }: EditDialogProps) => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();

  const onClose = useCallback(() => navigate("/profile"), []);
  const dialogType = getEditDialogType();
  return (
    <Dialog
      isOpen={isOpen}
      onUserDismiss={closeDialog}
      title={l10n.getString(`${dialogType}-edit-dialog-title`)}
    >
      <div className="margin--top--s" />
      <Routes>
        <Route
          path="/address"
          element={<AddressEditDialogBody closeDialog={onClose} />}
        />
        <Route
          path="/email"
          element={<EmailEditDialogBody closeDialog={onClose} />}
        />
        <Route
          path="/phone"
          element={<PhoneNumberEditDialogBody closeDialog={onClose} />}
        />
        <Route
          path="/username"
          element={<UsernameEditDialogBody closeDialog={onClose} />}
        />
      </Routes>
    </Dialog>
  );
};

export default EditDialog;
