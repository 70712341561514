import React, { useEffect } from "react";
import { useChatVisibilityContext } from "./ChatVisibilityContext";

const useSetChatVisibility = (componentId: string, visibility: boolean) => {
  const { registerComponent, unregisterComponent } = useChatVisibilityContext() || {};

  useEffect(() => {
    // Register the component's visibility preference when it mounts or updates
    registerComponent?.(componentId, visibility);

    // Unregister the component's visibility preference when it unmounts
    return () => {
      unregisterComponent?.(componentId);
    };
  }, [componentId, visibility, registerComponent, unregisterComponent]);
};

export default useSetChatVisibility;
