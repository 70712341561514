import React, {
  useRef,
  Children,
  isValidElement,
  cloneElement,
  useEffect,
  useCallback,
  useState,
  CSSProperties,
  ReactElement,
  ReactNode,
  ChangeEvent,
  KeyboardEvent,
  forwardRef,
  ForwardedRef,
} from "react";
// we seem to have trouble loading types from classcat
// @ts-ignore
import cc from "classcat";
import { Check, ChevronUp, ChevronDown } from "react-feather";
import { TextInput, Button } from "@narmi/design_system";

const transparentOverlayStyle: CSSProperties = {
  position: "fixed",
  inset: 0,
  backgroundColor: "transparent",
  zIndex: 999,
  cursor: "pointer",
};
const cardStyle: CSSProperties = {
  zIndex: 1000,
  position: "absolute",
  top: 0,
  left: 0,
  maxHeight: "310px",
  backgroundColor: "var(--color-white)",
  width: "100%",
  boxSizing: "content-box",
  display: "flex",
  flexFlow: "column nowrap",
  border: "1px solid var(--theme-primary)",
  borderRadius: "4px",
};
const bodyStyle: CSSProperties = {
  overflowY: "auto",
  maxHeight: "100%",
  width: "100%",
};

interface ChevronProps {
  open: boolean;
  setOpen: (a: boolean) => void;
}

const Chevron = ({ open, setOpen }: ChevronProps) => {
  const style: CSSProperties = {
    position: "absolute",
    top: "12px",
    right: "6px",
    strokeWidth: "1",
  };
  if (!open) {
    return (
      <ChevronDown
        onClick={(e) => {
          // to avoid event listeners above interfering with opening the dropdown
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          setOpen(true);
        }}
        style={style}
      />
    );
  }
  return (
    <ChevronUp onClick={() => setOpen(false)} style={{ ...style, top: "4px", zIndex: 1000 }} />
  );
};

interface DropdownListItemProps {
  children: ReactNode;
  anyItemSelected: boolean;
  isSelected: boolean;
  isIndented: boolean;
  boldSelected: boolean;
  onClick: () => void;
  closeDropdown: () => void;
  ariaLabel: string;
}
/**
 * ⚠️ DEPRECATED
 * Please use NDS `Combobox` or `Select` directly instead.
 * Thank you for your service, Dropdown.
 *
 * Need additional features from NDS?
 * [File an issue](https://github.com/narmi/design_system/issues)
 */
export const DropdownListItem = forwardRef(
  (
    {
      children,
      anyItemSelected = false,
      isSelected = false,
      isIndented = false,
      boldSelected = true,
      onClick = () => null,
      closeDropdown = () => null,
      ariaLabel = "",
    }: DropdownListItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const PreListItemStyling = () => {
      /* if any item has been selected or it should always be indented
       (which happens when it is part of a group), indent a little.
       if this item has been selected, display a checkmark. */
      if (!isIndented && !anyItemSelected && !isSelected) return null;

      return (
        <span className="alignChild--center--center pre-option-checkmark">
          {
            <Check
              color="var(--theme-primary)"
              width="20px"
              height="20px"
              style={{
                marginRight: "8px",
                opacity: isSelected ? 1 : 0,
              }}
            />
          }
        </span>
      );
    };

    return (
      <div
        role="option"
        tabIndex={0}
        aria-label={ariaLabel}
        aria-selected={isSelected}
        className="dropdown-list-item hoverable clickable"
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            onClick();
            closeDropdown();
          }
        }}
        onClick={() => {
          onClick();
          closeDropdown();
        }}
        ref={ref}
      >
        <span className="alignChild--left--center">
          <PreListItemStyling />
          <span
            className={cc([
              "dropdown-list-item-content",
              { "fontWeight--normal": isSelected && boldSelected },
            ])}
            color={isSelected && boldSelected ? "var(--color-black)" : "var(--color-grey)"}
          >
            {children}
          </span>
        </span>
      </div>
    );
  }
);

interface DropdownLinkItemProps {
  children: ReactNode;
  anyItemSelected: boolean;
  isIndented: boolean;
  setModalOpen: (value: boolean) => void;
  closeDropdown: () => void;
  ariaLabel: string;
}
/**
 * ⚠️ DEPRECATED
 * Please use NDS `Combobox` or `Select` directly instead.
 * Thank you for your service, Dropdown.
 *
 * Need additional features from NDS?
 * [File an issue](https://github.com/narmi/design_system/issues)
 */
export const DropdownLinkItem = ({
  children,
  anyItemSelected,
  isIndented = false,
  setModalOpen,
  closeDropdown,
  ariaLabel = "",
}: DropdownLinkItemProps) => {
  return (
    <div
      role="option"
      tabIndex={0}
      className="dropdown-list-item dropdown-link-item hoverable"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          setModalOpen(true);
          closeDropdown();
        }
      }}
      onClick={() => {
        setModalOpen(true);
        closeDropdown();
      }}
      aria-label={ariaLabel}
    >
      <div style={{ height: "20px", display: "flex", alignItems: "left" }}>
        <Button
          kind="plain"
          style={anyItemSelected || isIndented ? { marginLeft: "36px" } : undefined}
          type="button"
        >
          {children}
        </Button>
      </div>
    </div>
  );
};

/**
 * ⚠️ DEPRECATED
 * Please use NDS `Combobox` or `Select` directly instead.
 * Thank you for your service, Dropdown.
 *
 * Need additional features from NDS?
 * [File an issue](https://github.com/narmi/design_system/issues)
 */
export const DropdownItemGroupHeader = ({ group }: { group: ReactNode }) => {
  /* for rendering the header for a group of items (e.g. accounts grouped by type) */
  return (
    <div className="dropdown-list-item">
      <span
        className="fontWeight--semibold"
        style={{ textTransform: "capitalize" }}
        color="var(--nds-black)"
      >
        {group}
      </span>
    </div>
  );
};

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}
/**
 * ⚠️ DEPRECATED
 * Please use NDS `Combobox` or `Select` directly instead.
 * Thank you for your service, Dropdown.
 *
 * Need additional features from NDS?
 * [File an issue](https://github.com/narmi/design_system/issues)
 */
export const SearchBar = forwardRef(
  ({ searchTerm, setSearchTerm }: SearchBarProps, ref: ForwardedRef<HTMLInputElement>) => {
    /* For rendering the input used to search for a specific item */
    return (
      <div className="dropdown-search">
        <TextInput
          search
          value={searchTerm}
          icon="search"
          placeholder="Search"
          className="dropdown-search-text-input"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
          style={{ margin: 0, color: "--color-mediumGrey" }}
          ref={ref}
        />
      </div>
    );
  }
);

interface DropdownProps {
  children: ReactNode;
  defaultOpen: boolean;
  triggerLabel: ReactNode;
  triggerValue: string | number;
  error: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  ariaLabel: string;
  keyHandler: (event: KeyboardEvent<HTMLDivElement>) => void;
}

/**
 * ⚠️ DEPRECATED
 * Please use NDS `Combobox` or `Select` directly instead.
 * Thank you for your service, Dropdown.
 *
 * Need additional features from NDS?
 * [File an issue](https://github.com/narmi/design_system/issues)
 */
export const Dropdown = ({
  children,
  defaultOpen = false,
  triggerLabel,
  triggerValue,
  error,
  isOpen,
  setIsOpen,
  ariaLabel = "",
  keyHandler = (event) => {},
}: DropdownProps) => {
  /*
   * Dropdown menu
   * usage:
   *   <Dropdown
   *     triggerLabel={props.label}
   *     triggerValue={props.value}
   *     {...props}
   *   >
   *     <ContentToShowOnExpand />
   *   </Dropdown>
   */
  const isControlled = typeof isOpen !== "undefined" && typeof setIsOpen !== "undefined";
  const [uncontrolledOpen, uncontrolledSetOpen] = useState(defaultOpen);
  const setOpen = isControlled ? setIsOpen : uncontrolledSetOpen;
  const open = isControlled ? isOpen : uncontrolledOpen;
  const openDropdown = () => {
    setOpen(true);
  };
  const closeDropdown = () => {
    setOpen(false);
  };

  const containerElement = useRef<HTMLDivElement>(null);
  const handleClick = useCallback((event: Event) => {
    const isClickInside = containerElement.current?.contains(event.target as Node | null);

    if (!isClickInside) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  const bodyContainer = useRef<HTMLDivElement>(null);
  const bodyChildren: Element[] = bodyContainer.current?.children
    ? Array.from(bodyContainer.current?.children)
    : [];

  return (
    <div
      role="button"
      tabIndex={0}
      aria-expanded={open}
      ref={containerElement}
      className="dropdown-container"
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (["Tab", "Escape"].includes(event.key)) {
          closeDropdown();
        } else if (event.key === "Enter") {
          openDropdown();
        } else if (event.key === "ArrowDown") {
          const { activeElement } = document;

          if (activeElement && !bodyChildren.includes(activeElement)) {
            (bodyChildren[0] as HTMLElement | null)?.focus?.();
          } else {
            (activeElement?.nextElementSibling as HTMLElement | null)?.focus?.();
          }
        } else if (event.key === "ArrowUp") {
          const { activeElement } = document;
          if (activeElement && bodyChildren.includes(activeElement)) {
            (activeElement.previousElementSibling as HTMLElement | null)?.focus?.();
          }
        }
        keyHandler(event);
      }}
    >
      <TextInput
        onClick={openDropdown}
        label={triggerLabel}
        value={triggerValue}
        aria-label={ariaLabel || triggerLabel}
        error={error}
        tabIndex={-1}
        readOnly
      />
      {open && (
        <>
          <div
            role="button"
            tabIndex={0}
            style={transparentOverlayStyle}
            onKeyUp={({ key }) => {
              if (key === "Enter") {
                closeDropdown();
              }
            }}
            onClick={closeDropdown}
          ></div>
          <div aria-modal tabIndex={-1} role="dialog" style={cardStyle}>
            <div ref={bodyContainer} style={bodyStyle}>
              {Children.map<ReactNode, ReactNode>(
                Children.toArray(children),
                (child: ReactNode, i) =>
                  isValidElement(child) &&
                  cloneElement(child as ReactElement, {
                    closeDropdown, // mutate our children to have these extra props
                    key: i,
                  })
              )}
            </div>
          </div>
        </>
      )}
      <Chevron open={open} setOpen={setOpen} />
    </div>
  );
};
