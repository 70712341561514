import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLockBodyScroll } from "@narmi/design_system";
import { SESSION_HEARTBEAT, LANG_CHANGE } from "../browserCustomEvents";

const sendMessageToIframe = (data) => {
  if (window.iFrameResizer) {
    window.iFrameResizer.sendMessage(data);
  }
};

const ScrollingIframe = ({ height, src, srcDoc, title, action, signedRequest, code }) => {
  const [isBodyScrollLocked, setIsBodyScrollLocked] = useState(false);
  // Protects against double rendering
  // <https://github.com/narmi/banking/issues/42592>
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const scrollingIFrameFormRef = React.useRef();
  const iFrameRef = React.useRef();
  useLockBodyScroll(isBodyScrollLocked);

  useEffect(() => {
    if (!hasSubmitted) {
      scrollingIFrameFormRef.current?.submit();
      setHasSubmitted(true);
    }
  }, [scrollingIFrameFormRef.current, hasSubmitted]);

  // Handles messages sent to our parent frame
  // from the iframed page
  //
  // Uses DOM methods via the ref for the iframe element to prevent
  // unnecessary rerenders from state updates.
  const handleIframeMessage = (args) => {
    const { message } = args;

    if (typeof message === "object" && iFrameRef.current) {
      const iFrameEl = iFrameRef.current;

      // documented iframe scroll control
      if (typeof message.allowIframeScroll !== "undefined") {
        iFrameEl.setAttribute("scrolling", message.allowIframeScroll.toString());
      }

      // legacy iframe scroll control
      if (typeof message.setIsScrollEnabled !== "undefined") {
        iFrameEl.setAttribute("scrolling", message.setIsScrollEnabled.toString());
      }
    }

    // parent frame scroll control
    if (typeof message.allowParentScroll !== "undefined") {
      setIsBodyScrollLocked(!message.allowParentScroll);
    }
  };

  // - Initialize iframe-resizer on iframe load event
  // - Listen for messages from iframed page
  useEffect(() => {
    const iframeEl = document.getElementById("app-iframe");
    if (typeof window.iFrameResize === "function" && !window.hasIFrameResizerLoaded) {
      iframeEl.addEventListener("load", () => {
        window.iFrameResize(
          {
            log: true,
            sizeWidth: false,
            scrolling: true,
            checkOrigin: false,
            onMessage: handleIframeMessage,
          },
          "#app-iframe"
        );
        window.hasIFrameResizerLoaded = true;
      });

      // use custom event fired by `InactivityDialog` to post
      // a heartbeat to the iframe
      window.addEventListener(SESSION_HEARTBEAT, () => {
        sendMessageToIframe({ isSessionActive: true });
      });

      // use custom event fired by `useFluentLocalization` to
      // post a language change message to the iframe
      window.addEventListener(LANG_CHANGE, ({ detail: l10n }) => {
        const lang = [...l10n.bundles][0].value.locales[0];
        sendMessageToIframe({ lang });
      });
    }
  });

  return (
    <>
      {action && (
        <form action={action} method="POST" target="app-iframe" ref={scrollingIFrameFormRef}>
          <input type="hidden" name="signed_request" value={signedRequest} />
          <input type="hidden" name="code" value={code} />
        </form>
      )}
      <iframe
        ref={iFrameRef}
        title={title}
        src={src}
        srcDoc={srcDoc}
        scrolling="yes"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowFullScreen=""
        width="100%"
        style={{ minWidth: "320px" }}
        height={height}
        frameBorder="0"
        name="app-iframe"
        id="app-iframe"
        allow={`geolocation; clipboard-read; clipboard-write self ${action}`}
      />
    </>
  );
};

ScrollingIframe.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  src: PropTypes.string,
  srcDoc: PropTypes.string,
  action: PropTypes.string,
  signedRequest: PropTypes.string,
  code: PropTypes.string,
};

ScrollingIframe.defaultProps = {
  height: "600px",
};

export default ScrollingIframe;
