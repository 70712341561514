import { useChatVisibilityContext } from "../ChatVisibilityContext";

interface AgentIQBubbleProps {
  isEnabled?: boolean;
}

const AgentIQBubble = ({ isEnabled }: AgentIQBubbleProps) => {
  const { isChatVisible } = useChatVisibilityContext();

  // If the feature flag is NOT set to true, hide the bubble
  if (!isEnabled || window.location.pathname.match(/^\/login/) || !isChatVisible) {
    return null;
  }

  return (
    <div
      id="agentIQBubble"
      className="agentIQChatBubble"
      onClick={() => {
        window?.__aiq?.renderChat();
        window?.__aiq?.openChatWindow();
      }}
    >
      <span className="narmi-icon-message-square" title="Chat with an Agent" />
    </div>
  );
};

export default AgentIQBubble;
