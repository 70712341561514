import React, { createContext, useContext, useState, ReactNode, useMemo, useCallback } from "react";

type ChatVisibilityContextType = {
  registerComponent: (id: string, isVisible: boolean) => void;
  unregisterComponent: (id: string) => void;
  isChatVisible: boolean;
};

const ChatVisibilityContext = createContext<ChatVisibilityContextType>({
  isChatVisible: true,
  registerComponent: () => {},
  unregisterComponent: () => {},
});

interface ChatVisibilityProviderProps {
  children: ReactNode;
}

export const ChatVisibilityProvider = ({ children }: ChatVisibilityProviderProps) => {
  const [componentVisibilities, setComponentVisibilities] = useState<Record<string, boolean>>({});

  const registerComponent = useCallback((id: string, isVisible: boolean) => {
    setComponentVisibilities((prev) => ({ ...prev, [id]: isVisible }));
  }, []);

  const unregisterComponent = useCallback((id: string) => {
    setComponentVisibilities((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
  }, []);

  const isChatVisible = useMemo(
    () => Object.values(componentVisibilities).every((visible) => visible),
    [componentVisibilities]
  );
  const value = useMemo(
    () => ({ registerComponent, unregisterComponent, isChatVisible }),
    [registerComponent, unregisterComponent, isChatVisible]
  );

  return <ChatVisibilityContext.Provider value={value}>{children}</ChatVisibilityContext.Provider>;
};

export const useChatVisibilityContext = () => useContext(ChatVisibilityContext);
