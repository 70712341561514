import {
  Button,
  ContextForm,
  Row,
  TextInput,
  useFormData,
  useNotificationContext,
  validateEmail,
} from "cerulean";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";

interface EmailEditDialogBodyProps {
  closeDialog: () => void;
}

const EmailEditDialogBody = ({ closeDialog }: EmailEditDialogBodyProps) => {
  const { currentUser } = useCurrentUser();
  const { sendNotificationToParent, sendNotification } =
    useNotificationContext();
  const { formData, onChange } = useFormData({
    newEmail: "",
  });

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    try {
      const response = await currentUser?.updateEmail(formData.newEmail);
      if (response?.message) {
        sendNotificationToParent({ type: "success", text: response?.message });
      }
      closeDialog();
      callback();
    } catch (error) {
      if (error instanceof Error) {
        sendNotification({ type: "negative", text: error?.message });
        callback(error?.message);
      } else if (typeof error === "string") {
        sendNotification({ type: "negative", text: error });
        callback(error);
      }
    }
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field field="currentEmail">
        <TextInput
          label="Current email address"
          value={currentUser?.email}
          aria-label="Current email address"
          disabled
        />
      </ContextForm.Field>
      <ContextForm.Field
        required
        validate={(value: string) => validateEmail(value)}
      >
        <TextInput
          field="newEmail"
          label="New email address"
          value={formData?.newEmail}
          aria-label="new email address"
        />
      </ContextForm.Field>

      <div className="margin--bottom--xl" />
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button
            type="button"
            onClick={closeDialog}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <ContextForm.Action onSubmit={onSubmit}>
            <Button kind="primary" label="Save changes" />
          </ContextForm.Action>
        </Row.Item>
      </Row>
    </ContextForm>
  );
};

export default EmailEditDialogBody;
