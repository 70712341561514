import React, { useEffect } from "react";
import { useChatVisibilityContext } from "./ChatVisibilityContext";

const setChatVisibility = (visibility: boolean) => {
  const ctaElement = document.querySelector("#salemove") as HTMLElement | null;

  if (visibility) {
    ctaElement?.style?.removeProperty("display");
  } else {
    ctaElement?.style?.setProperty("display", "none", "important");
  }
};

export const ChatVisibilityHandler = () => {
  const { isChatVisible } = useChatVisibilityContext();

  useEffect(() => {
    setChatVisibility(isChatVisible);
  }, [isChatVisible]);

  return null;
};
