import React, { ReactNode, useRef } from "react";
import { Dialog as NDSDialog } from "@narmi/design_system";
import { NotificationContextProvider } from "../NotificationContext";
import { useSetChatVisibility } from "../ChatVisibilityContext";

interface DialogProps {
  children: ReactNode;
  isOpen?: boolean;
  title: string;
  onUserDismiss?: () => void;
  headerStyle?: "bordered";
  footer?: ReactNode;
  width?: string;
  testId?: string;
}

const Dialog = ({
  children,
  isOpen,
  title,
  onUserDismiss,
  headerStyle,
  footer,
  width,
  testId,
}: DialogProps) => {
  const dialogIdRef = useRef(`Dialog-${Math.random().toString(36).substr(2, 9)}`);
  useSetChatVisibility(dialogIdRef.current, !isOpen);

  return (
    <NDSDialog
      isOpen={isOpen}
      title={title}
      onUserDismiss={onUserDismiss}
      headerStyle={headerStyle}
      footer={footer}
      width={width}
      testId={testId}
    >
      <NotificationContextProvider isPortaled={false}>{children}</NotificationContextProvider>
    </NDSDialog>
  );
};

export default Dialog;
