import { useLocalization } from "@fluent/react";
import { Navigate } from "react-router-dom";
import User from "byzantine/src/User";
import {
  Button,
  ContextForm,
  Row,
  TextInput,
  useFormData,
  useNotificationContext,
} from "cerulean";
import type { InstitutionFeatures } from "byzantine/src/types";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";

interface UsernameEditDialogProps {
  closeDialog: () => void;
}

const NewUsernameInput = ({
  onChange,
  value,
  field,
  ...textInputProps
}: {
  onChange?: (val: unknown) => void;
  value?: string;
  field: string;
}) => {
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = event.target.value.toLowerCase();
    if (onChange) {
      onChange(parsedValue);
    }
  };
  return (
    <TextInput
      label="Username"
      onChange={handleInput}
      value={value}
      aria-label={field}
      {...textInputProps}
    />
  );
};

const UsernameEditDialogBody = ({ closeDialog }: UsernameEditDialogProps) => {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { sendNotification } = useNotificationContext();
  const { formData, onChange } = useFormData({
    username: currentUser?.username,
  });
  const { l10n } = useLocalization();
  const features = useUserFeatures() as InstitutionFeatures;

  const validateField = (value: string) => {
    if (!value) {
      return l10n.getString("error-required", null, "Must be filled in.");
    }

    if (value.toLowerCase() === currentUser?.username) {
      return l10n.getString(
        "error-same-username",
        null,
        "New username same as current.",
      );
    }

    return null;
  };

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    try {
      const updatedUser = await currentUser?.updateUsername(formData.username);
      if (updatedUser) {
        // `cleanedUpdatedUser` is a `updatedUser` object with properties removed
        // if they are empty or undefined. We preserve `null` properties.
        //
        // We do this to prevent overriding unchanged values in `...currentUser`.
        const cleanedUpdatedUser = Object.fromEntries(
          Object.entries(updatedUser).filter(
            ([, v]) =>
              ![undefined, '""', "[]", "{}"].includes(JSON.stringify(v)),
          ),
        );
        setCurrentUser(new User({ ...currentUser, ...cleanedUpdatedUser }));
      }
      callback();
      sendNotification({ type: "success", text: "Username updated." });
      closeDialog();
    } catch (error: unknown) {
      const e = error as { username: string };
      callback(e.username);
    }
  };

  if (!features?.can_change_username) {
    return <Navigate to={"/profile"} replace />;
  }

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field validate={validateField}>
        <NewUsernameInput field="username" />
      </ContextForm.Field>
      <div className="margin--bottom--xl" />
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button
            type="button"
            onClick={closeDialog}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <ContextForm.Action onSubmit={onSubmit}>
            <Button
              kind="primary"
              label="Save changes"
              disabled={formData?.username === currentUser?.username}
            />
          </ContextForm.Action>
        </Row.Item>
      </Row>
    </ContextForm>
  );
};
export default UsernameEditDialogBody;
